$primary_font_family: 'Jura', sans-serif;
$secondary_font_family: 'Poppins', sans-serif;
$primary_color: #253d61;
$secondary_color: #34495E;
$white: #ffffff;
$sidebar-bg:#34495E;
$success: #008000;
$danger: #F00;
$warning: #FFB800;
$disable: #A9A7A7;
$text-blue: #00BEFF;
$blue-color: #00BEFF;
$gray-color: #5D6D7E;





