/* You can add global styles to this file, and also import other style files */
.btn-check:checked + .btn{
    background-color:#3399FF !important;
    border-color:aliceblue !important
}
.btn-outline-secondary{
    border-color:aliceblue !important;
    color:aliceblue !important;
}
.pagination:active{
    background-color:#3399FF !important;
    border-color:aliceblue !important
}

.offcanvas-body {
    overflow: auto !important;
}
