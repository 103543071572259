// Variable overrides
//
// If you want to customize your project please add your variables below.

$enable-deprecation-messages: false !default;

$table-bg:none;
$body-emphasis-color:#ffffff;
$font-family-base:'Jura'

