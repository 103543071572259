@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import '../src/assets/scss/variable';

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
}

html{
    height: 100%;
    min-width: auto;
}
html, body{
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
    min-height: 100%;
    font-family: $primary_font_family;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    @media print{
        padding: 8px 5px;
    }
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    line-height: 1.1;
    margin: 0;
  }
  
  ul, li {
      margin-bottom:0;
      margin-top: 0;
      padding: 0px;
      list-style: none;
  }
  
  a, button{
    transition: all .5s ease-in-out;
    outline: none;
    box-shadow: none;
    border: 0;
    &:focus{
        outline: none;
        box-shadow: none;
        border: 0;
    }
  }

.cursor {
    cursor: pointer;
}
  svg.success {

    &,
    >* {
      fill: $success;
    }
  }

  svg.danger {

    &,
    >* {
      fill: $danger;
    }
  }

  svg.warning {

    &,
    >* {
      fill: $warning;
    }
  }

  svg.disable {

    &,
    >* {
      fill: $disable;
    }
  }

  .offcanvas{
    overflow: hidden;
    overflow-y: auto;
  }


  .card {
    border-radius: 5px;
    margin-bottom: 15px;
    .card-head {
        background: $primary_color;
        border-radius: 5px 5px 0px 0px;
        padding: 10px 15px;

        h3 {
            color: #FFF;
            font-family: $primary_font_family;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            margin: 0;
            @media screen and (max-width:640px){
                font-size: 18px;
            }
            @media screen and (max-width:414px){
                font-size: 14px;
            }
            @media print{
                font-size: 14px;
            }
        }
        address {
            color: #fff;
            font-family: $primary_font_family;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 1.92px;
            margin: auto;
            text-align: center;
            @media print{
                font-size: 10px;
            }
            
        }
    }

    .card-body {
        background: $white;
        @media screen and (max-width:585.5px) {
            padding-left: 5px;
            padding-right: 5px;
            
        }
        .vehical-name-row {
            display: flex;
            padding: 15px 0;
            gap: 10px;
            margin-bottom:10px;
            h3 {
                color: $primary_color;
                font-family: $primary_font_family;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
        }

        .vehical-detail-row {
            display: flex;
            padding: 0;
            gap: 10px;
            @media screen and (max-width: 585.5px) {
                padding: 0;
                margin-bottom: 0;
            }
            @media print {
                padding: 0;
                gap:0;
                box-sizing: border-box;
                margin-bottom: 10px;
            }
            h3 {
                color: $primary_color;
                font-family: $primary_font_family;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                letter-spacing: 1.92px;
                @media print {
                    font-size: 16px;
                  }
                small {
                    color: #A9A7A7;
                    font-size: 14px;
                    width: 100%;
                    display: inline-flex;
                }
            }
            img{
                height: 40px;
            }
        }
    }
    .vehical-all-details{
        width: 100%;
        display: flex;
        ul{
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 10px;
            justify-content: space-between;
            @media print{
                gap: 0;
                }
            li{
                display: flex;
                justify-content: space-between;
                padding: 5px 0;
                text-transform: uppercase;
                flex-basis: 30%;
                border-bottom: 0.5px solid #D6DCE5;
                flex-direction: column;
                @media print{
                    flex-basis: 46%;
                }
                
                span{
                    color: $primary_color;
                    font-family: $primary_font_family;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-align: left;
                }
                strong{
                    color: $primary_color;
                    font-family: $primary_font_family;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    letter-spacing: 0.5px;
                    text-align: left;
                }
            }
        }
    }

    .cat-listing{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        h3{
            padding-left: 10px;
            color: $primary_color;
            font-family: $primary_font_family;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            @media screen and (max-width: 585.5px) {
                font-size: 12px;
                line-height: 14px;
            }
        }
        .sub-cat-count{
            display: flex;
            padding-left: 30px;
            align-items: center;
            gap: 10px;
            @media screen and (max-width: 585.5px) {
                padding-left: 10px;
            }
            strong{
                color: #00B050;
                text-align: center;
                font-family: $primary_font_family;
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            span{
                color: #A9A7A7;
                font-family: $primary_font_family;
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
    .sub-cat-listing{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 0;
        @media print{
            padding: 10px 0;
            }
        span{
            padding-left: 10px;
            color: #A9A7A7;
            font-family: $primary_font_family;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px;
        }
        ul{
            display: flex;
            justify-content: flex-start;
            width: 100%;
            padding-top: 20px;
            gap: 10px;
            @media print{
                gap:0;
                }
            li{
                display: flex;
                justify-content: flex-start;
                vertical-align: middle;
                align-items: center;
                @media print{
                    padding: 2px 0;
                    }
            }
        }
        &.as-cat-list{
            ul{
                flex-wrap: wrap;
                li{
                    width: 47%;
                    flex-basis: 47%;
                    @media screen and (max-width: 991px){
                        width: 45%;
                        flex-basis: 45%;
                    }
                    @media screen and (max-width: 585.5px){
                        width: 100%;
                        flex-basis: 100%;
                    }
                    svg{
                        width: 20px;
                    }
                }
            }
        }
    }
}


.print-only{
    display: none;
    margin-bottom: 15px;
}

@media print {
    @page {
        size: A4;
        margin: 0;
        padding: 0;
        background: transparent;
      }
      *{
        box-shadow: none;
      }
      ul,li{
        list-style: none;
      }
      html, body {
          width: 210mm;
          height: 282mm;
          font-size: 11px;
          background: transparent;
          overflow: visible;
          margin: 0;
          background: #D6DCE5;
          font-family: $primary_font_family;
      }
      body{
        padding-top: 0px;
      }
      .print-only{
        display: block;
      }
    .pagebreak { 
        padding: 0px 0;
        clear: both;
        page-break-before: always; 
    } /* page-break-after works, as well */
        .p-media-0{
            padding: 0;
        }
    .offcanvas-body {
        overflow-y: auto;
        }
        
#singlePageReport {
    header {
        width: 100%;
        display: flex;
        flex-direction: column;
    
        .row-logo {
            background: $primary_color;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px 8px;
            position: relative;
            @media print{
                box-shadow: none;
                margin: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
            img{
                height: 30px;
            }
        }
    
        .row-title {
            background: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2px 5px;
            position: relative;
            @media print{
                box-shadow: none;
                margin: 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
    
            h1 {
                color: $primary_color;
                font-family: $primary_font_family;
                font-size: 9px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                margin: 0;
            }
    
            h3 {
                color: $primary_color;
                font-family: $primary_font_family;
                font-size: 9px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px;
                margin: 0;
            }
        }
    }
    
        .cost-summary-row {
            ul{
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                li{
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    gap: 0;
                    width: calc(100% - 30px);
                    margin: 0 5px;
                    .cost-block {
                        border-radius: 2px;
                        color: $secondary_color;
                        font-family: $primary_font_family;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        background: #eee;
                        width: 100%;
                        padding: 10px;        
                        &--danger {
                          @extend .cost-block;
                          background: rgba(255, 0, 0, 0.1);
                          color: #f00;
                        }
                      }
                }
            }
        }
    }
}
