// Here you can add other styles

// custom .chartjs-tooltip-body-item padding
@import "charts";

// custom tweaks for scrollbar styling (wip)
@import "scrollbar";

@import '../assets/scss/variable';

// custom calendar today cell color
.calendar-cell.today {
  --cui-calendar-cell-today-color: var(--cui-info) !important;
}

// custom select week cursor pointer
.select-week .calendar-row.current {
  cursor: pointer;
}

hr.hr-sepertor {
  background-color: $gray-color;
  height: 1px;
  border: 0;
  margin: 15px 0px;
  width: 100%;
  display: flex;
}
.match-cls{
  background-color: #5D6D7E !important;
  color: white !important;;
}
.form-control {
  background-color: #fff;
  border-radius: 0rem;
  &:focus{
    box-shadow: none;
    outline: none;
  }
  &.readonly-field{
    background-color: transparent;
    border: 0.5px solid #5D6D7E;
  }
}

.form-control:focus {
  background-color: #fff;
  border-radius: 0rem;
}

.form-select {
  background-color: #fff;
  border-radius: 0rem;
  &.active-status {
    background-color: #00B050;
    color: $white;
  }

  &.inactive-status, &.onHold-status {
    color: $white;
    background-color: #FFA800;
  }

  &.suspended-status, &.notPaid-status {
    background-color: #9747FF;
    color: $white;
  }

  &.disabled-status, &.pending-status {
    color: $white;
    background-color: #F00;
  }
  &.cancel-status {
    color: $white;
    background-color: #34495E;
  }
  &:focus {
    // box-shadow: 0 3px 0px 0px rgb(0 190 255);
    // outline: none;
    // border-color: $blue-color;
        box-shadow: none;
        outline: none;
        border-color: $blue-color;
  }
  &.readonly-field{
    background-color: transparent;
    border: 0.5px solid #5D6D7E;
  }
}

.bg-body-tertiary {
  background-color: #D6DCE5 !important;
  color: #5D6D7E !important;
}

.pagination>li>a {
  background-color: white;
  color: purple;
}

.heading {
  line-height: 30px;
  font-weight: 700;
  color: #D6DCE5;
  display: flex;
  align-items: center;
  font-family: $primary_font_family;
  text-transform: uppercase;

  svg {
    margin-right: 15px;
  }
}

.filter-row {
  display: flex;
  align-items: center;

  .text-label {
    color: #D6DCE5;
    text-align: center;
    font-family: $primary_font_family;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .ng-multiselect-dropdown {
    .multiselect-dropdown {
      .dropdown-btn {
        border-radius: 2px;
        border: 0.5px solid #D6DCE5;
      }
    }
  }
}

// Search Input Filter for table listing
.search {
  width: 100%;
  background: rgba(0, 190, 255, 0.10) url('../assets/images/search.png') 96% 50% no-repeat;
  border: 0.5px solid #D6DCE5;
  color: $text-blue;
  font-family: $primary_font_family;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 24px;
  border-radius: 2px;

  &:focus {
    border-color: $text-blue;
    background: rgba(0, 190, 255, 0.10) url('../assets/images/search.png') 96% 50% no-repeat;
    color: $white;
  }

  &::-webkit-input-placeholder {
    color: $text-blue;
    ;
  }

  &:-moz-placeholder {
    color: $text-blue;
    ;
  }

  &::-moz-placeholder {
    color: $text-blue;
    ;
  }

  &:-ms-input-placeholder {
    color: $text-blue;
    ;
  }
}

// Table coreUiCustomeTable
table.coreUiCustomeTable {

  th,
  td {
    padding: 18px 20px;
  }

  td {
    color: $white;
    font-family: $primary_font_family;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    vertical-align: middle;

    span.text-hightlight {
      color: $text-blue;
      font-size: 18px;
      vertical-align: middle;
    }
    span.text-danger, span.text-negative {
      color: #FFA8A8;
      font-size: 18px;
      vertical-align: middle;
    }
  }
}


.viewItems {
  display: flex;
  width: 100%;


  label {
    color: $gray-color;
    font-family: $primary_font_family;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }

  span {
    color: $gray-color;
    font-family: $primary_font_family;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 15px;

    li {
      position: relative;
      border: .5px solid $gray-color;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%;

      label {
        text-align: center;
      }

      svg {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      strong {
        color: $blue-color;
        text-align: center;
        font-family: $primary_font_family;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

// Form section
.form-section {
  h3 {
    color: $gray-color;
    font-family: $primary_font_family;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h4{
    color: $gray-color;
    font-family: $primary_font_family;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 15px;
  }

  .form-group {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    label {
      color: $gray-color;
      font-family: $primary_font_family;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-bottom: 5px;
      text-transform: uppercase;
      sup{
        color: $danger;
        font-size: 13px;
        line-height: 1;
        top: -2px;
      }
    }

    .form-control {
      border-radius: 2px;
      border: 0.5px solid $gray-color;
      background: $white;
      color: $gray-color;
      font-weight: 700;

      &.invalid,
      &.ng-invalid {
        border-color: #F00;
        border-width: 1px;
      }

      &.active-status {
        background: #00B050;
        color: $white;
        text-transform: uppercase;
      }

      &.inactive-status, &.onHold-status {
        color: $white;
        background: #FFA800;
        text-transform: uppercase;
      }

      &.suspended-status, &.notPaid-status {
        background: #9747FF;
        color: $white;
        text-transform: uppercase;
      }

      &.disabled-status, &.pending-status {
        color: $white;
        background: #F00;
        text-transform: uppercase;
      }
      &.cancel-status {
        color: $white;
        background: #34495E;
        text-transform: uppercase;
      }
      &.form-search {
        margin: 0;
        background: $white url(../assets/img/icon-search.png) 97% 50% no-repeat;
        padding-right: 24px;
      }
      &.form-password{
        padding-right: 24px;
      }
      &.date-picker-input{
        background: $white url(../assets/img/icon-calendar.png) 97% 50% no-repeat;
        padding-right: 24px;
      }
      &:read-only{
        background: transparent;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 13px;
      }

      &:focus {
        box-shadow: none;
        outline: none;
        border-color: $blue-color;
      }
    }

    textarea.form-control {
      height: 142px;
      resize: none;
    }

    .icon-btn{
      padding: 0;
      outline: 0;
      box-shadow: none;
      position: absolute;
      cursor: pointer;
      background: none;
      right: 10px;
      top: 24px;
      svg{
        fill: $blue-color;
      }
    }

    .updloadFile-wrapper {
      position: relative;
      border-radius: 2px;
      border: 0.5px solid $gray-color;
      background: $white;
      display: flex;
      align-items: center;
      padding: 15px;

      &.display-logo-thumbnail {
        background: transparent;

        figure {
          margin: 0;
          width: 80%;
        }

        svg {
          position: absolute;
          z-index: 2;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
        }
      }

      input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 3;
      }

      span {
        font-family: $primary_font_family;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding-left: 10px;
      }
    }

    .checkboxWrap {
      display: flex;
      gap: 10px;
      align-items: center;
      align-items: center;
      margin-bottom: 10px;

      label {
        color: $gray-color;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin: 0;
      }
    }
  }

  .cta-btn {
    border-radius: 5px;
    border: 0;
    color: $white;
    text-align: center;
    font-family: $primary_font_family;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    padding: 12px;
    margin-top: 10px;

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &.cancel-btn {
      background: rgba(0, 0, 0, 0.25);
    }

    &.submit-btn {
      background: $blue-color;
    }
  }

  &.view-form-section {
    .form-group {
      .form-control {
        background: transparent;
        height: 38px;

        &.active-status {
          background: #00B050;
          color: $white;
        }

        &.inactive-status, &.onHold-status {
          color: $white;
          background: #FFA800;
        }

        &.suspended-status, &.notPaid-status {
          background: #9747FF;
          color: $white;
        }

        &.disabled-status, &.pending-status {
          color: $white;
          background: #F00;
        }
        &.cancel-status {
          color: $white;
          background: #34495E;
        }
      }
    }
  }

  &.edit-form-section {
    .form-group {
      .form-control {
        background: $white;
        &:read-only{
          background: transparent;
        }
        &.form-search {
          margin: 0;
          background: $white url(../assets/img/icon-search.png) 97% 50% no-repeat;
          padding-right: 24px;
        }
        &.date-picker-input{
          background: $white url(../assets/img/icon-calendar.png) 97% 50% no-repeat;
          padding-right: 24px;
        }

        &.active-status {
          background-color: #00B050;
          color: $white;
        }

        &.inactive-status, &.onHold-status {
          color: $white;
          background-color: #FFA800;
        }

        &.suspended-status, &.notPaid-status {
          background-color: #9747FF;
          color: $white;
        }

        &.disabled-status, &.pending-status {
          color: $white;
          background-color: #F00;
        }
        &.cancel-status {
          color: $white;
          background-color: #34495E;
        }
      }
    }
  }
}


.user-permission-wrap {
  display: flex;
  width: 100%;

  ul {
    display: flex;
    flex-direction: column;
    width: 100%;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin: 4px 0;

      h4 {
        color: $gray-color;
        font-family: $primary_font_family;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        color: $gray-color;
        font-family: $primary_font_family;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .checkWrap {
        position: relative;

        input {
          opacity: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 2;
          &.c-default{
            cursor: default;
          }

          &:checked {
            &+.checkboxCheck {
              border-color: $blue-color;
              background: $white;

              &:before {
                background: $blue-color;
                right: 2px;
                left: auto;
              }
            }
          }
        }

        .checkboxCheck {
          border: 2px solid $white;
          width: 36px;
          height: 18px;
          border-radius: 30px;
          background: transparent;
          position: relative;
          transition: all .5s ease-in-out;

          &:before {
            position: absolute;
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: $white;
            display: inline-block;
            content: '';
            left: 4px;
            top: 2px;
            transition: all .5s ease-in-out;
          }
        }
      }
    }
  }
}


.selection-result-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;

  h6 {
    color: $gray-color;
    font-family: $primary_font_family;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .field-wrap {
    width: 100%;
    display: flex;
    border: 0.5px solid $gray-color;
    border-radius: 2px;
    background: #D6DCE5;
    padding: 15px 15px;
    flex-direction: column;
    margin: 10px 0;
  }

  .selection-results {
    width: 100%;
    display: flex;
    padding: 0 15px;
    max-height: 100px;
    overflow-y: auto;

    ul {
      width: 100%;
      display: flex;
      flex-direction: column;

      li {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0px 0;
        gap: 10px;

        button {
          padding: 0;
          cursor: pointer;
          background: none;

          &:focus {
            outline: none;
            box-shadow: none;
            border: 0;
          }
        }

        h5 {
          color: $gray-color;
          font-family: $primary_font_family;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}

/* Pagination Color */
.pagination>li>a {
  background-color: #5D6D7E;
  color: #ffffff;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  color: #fff;
  background-color: #00BEFF;
  border-color: #ddd;
}

.pagination>.active>span {
  color: white;
  background-color: #00BEFF !Important;
  border: solid 1px #00BEFF !Important;
}

.pagination>.active>a:hover {
  background-color: #00BEFF !Important;
  border: solid 1px #00BEFF;
}

.pagination>.disabled>span,
.pagination>.disabled>span:hover,
.pagination>.disabled>a,
.pagination>.disabled>a:focus {
  color: #fff !Important;
  background-color: #5D6D7E !Important;
  border-color: #fff;
}


.c-pointer {
  cursor: pointer;
}

.c-default{
  cursor: default;
}


.active-block {
  width: 25px;
  height: 15px;
  background-color: #00B050;
  display: inline-block;

  &.sm-block {
    width: 14px;
    height: 14px;
  }
}

.inactive-block, .onHold-block {
  width: 25px;
  height: 15px;
  background-color: #FFA800;
  display: inline-block;

  &.sm-block {
    width: 14px;
    height: 14px;
  }
}

.suspended-block, .notPaid-block {
  width: 25px;
  height: 15px;
  background-color: #9747FF;
  display: inline-block;

  &.sm-block {
    width: 14px;
    height: 14px;
  }
}

.disabled-block, .pending-block {
  width: 25px;
  height: 15px;
  background-color: #FF0000;
  display: inline-block;

  &.sm-block {
    width: 14px;
    height: 14px;
  }
}

.cancel-block {
  width: 25px;
  height: 15px;
  background-color: #34495E;
  display: inline-block;

  &.sm-block {
    width: 14px;
    height: 14px;
  }
}

.status-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      padding: 0 10px;
      font-size: 13px;
      font-weight: 700;
    }
  }
}

.wrap{
  align-items: center;
    display: flex;
    gap: 5px;
}

.error{
 font-size: 12px;
}

.error-msg{
  font-size: 13px;
  color: $danger;
}

.theme-btn{
  background-color: $blue-color;
}

.hamburger-btn{
  background: #d6dce5;
  border-radius: 0;
  margin-left: auto;
  cursor: pointer;
  box-shadow: none;
  outline: none;
  border: 0;
  &:before{
    display: none;
  }
  &:focus{
    box-shadow: none;
    outline: none;
    border: 0;
  }
}

.logout-btn{
position: absolute;
right: 0px;
bottom:0px;
background: $blue-color;
cursor: pointer;
}